import '@css/main.pcss'

import Alpine from 'alpinejs'
import AsyncAlpine from 'async-alpine'
import collapse from '@alpinejs/collapse'
import focus from '@alpinejs/focus'
import persist from '@alpinejs/persist'
import validate from '@colinaut/alpinejs-plugin-simple-validate'

document.addEventListener('alpine:initialized', () => {
  document.documentElement.classList.remove('loading')
})

AsyncAlpine
  .init(Alpine)
  .data('slider', () => import('./components/slider'))
  .data('countdown', () => import('./components/countdown'))
  .data('eventSignupForm', () => import('./components/event-signup-form'))
  .data('contactForm', () => import('./components/contact-form'))
  .start()

Alpine.plugin(collapse)
Alpine.plugin(focus)
Alpine.plugin(persist)
Alpine.plugin(validate)
Alpine.start()

window.Alpine = Alpine

if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log('HMR')
  })
}
